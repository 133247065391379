import { useMemo } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';

import Logo from '../common/component/loading/logo';
import { useAuth } from '../common/hooks/use-auth';

const RequireAuth = () => {
  const { isAuth } = useAuth();
  const isLoading = false;

  const location = useLocation();

  const protectedRoute = useMemo(
    () => (isAuth ? <Outlet /> : <Navigate to='/login' state={{ from: location }} replace />),
    [isAuth]
  );

  return isLoading ? <Logo /> : protectedRoute;
};
export default RequireAuth;
