// Local storage keys
export const STORAGE_KEYS = {
  TOKEN: 'TOKEN',
  USER: 'USER',
  USER_EMAIL: 'USER_EMAIL'
};

export const ROUTER_KEYS = {
  HOME: '/',
  LINE: '/line',
  BUY: '/buy-online-billBoard',
  HALL_OF_FAME: '/hall-of-fame',
  ABOUT: '/about',
  CONTACT: '/contact',
  HISTORY: '/history',
  BANNER: '/billboard/:id/:title',
  THANK_YOU: '/:id/thank-you',
  TERMS: 'terms-of-service',
  ADMIN: '/admin',
  LOGIN: '/login'
};

export const QUERY_KEYS = {};
