export const Png = {
  dgDay: require('./bg-day.webp'),
  dgNight: require('./bg-night.webp'),
  dgErrorDay: require('./bg-error-day.webp'),
  dgErrorNight: require('./bg-error-night.webp'),
  headerDotted: require('./header_dotted.webp'),
  bannerAfter: require('./banner-main-after.webp'),
  littleBanner: require('./little-banner-placeholder.webp'),
  littleBannerAfter: require('./banner-little.webp'),
  logo: require('./logo.webp'),
  preload: require('./preload.gif'),
  bottom: require('./bottom.webp'),
  bgMobile: require('./bg-mobile.webp'),
  timeMobile: require('./time-mobile.webp'),
  timeBorder: require('./time-border.webp'),
  iconMobile: require('./icon-mobile.webp'),
  volumeIcon: require('./volume.webp'),
  volumeIconOff: require('./volume-off.webp'),
  expandIcon: require('./expand.webp'),
  iconBorder: require('./icon-border.webp'),
  burgerMenu: require('./burger-menu.webp'),
  burgerMenuClose: require('./burger-menu-close.webp'),
  mobileLogo: require('./mobile-logo.webp'),
  arrowLogo: require('./arrow-logo.webp'),
  mobileLittleBannerBottom: require('./mobile-little-banner-bottom.webp'),
  iconMinimize: require('./icon-minimize.png'),
  minimize: require('./minimize.svg'),

  line: {
    day: require('./line/line.webp'),
    dayHover: require('./line/line-hover.webp'),
    night: require('./line/line-night.webp'),
    nightHover: require('./line/line-hover-night.webp'),
    bannerStub: require('./line/your-banner-here.webp')
  },
  dollar: {
    day: require('./dollar/1dollar.webp'),
    dayHover: require('./dollar/1dollar-hover.webp'),
    night: require('./dollar/1dollar-night.webp'),
    nightHover: require('./dollar/1dollar-hover-night.webp')
  },

  about: {
    day: require('./about/about.webp'),
    dayHover: require('./about/about-hover.webp'),
    night: require('./about/about-night.webp'),
    nightHover: require('./about/about-hover-night.webp')
  },

  contact: {
    day: require('./contact/contact.webp'),
    dayHover: require('./contact/contact-hover.webp'),
    night: require('./contact/contact-night.webp'),
    nightHover: require('./contact/contact-hover-night.webp')
  },

  fame: {
    day: require('./fame/fame.webp'),
    dayHover: require('./fame/fame-hover.webp'),
    night: require('./fame/fame-night.webp'),
    nightHover: require('./fame/fame-hover-night.webp')
  },

  home: {
    day: require('./home/home.webp'),
    dayHover: require('./home/home-hover.webp'),
    night: require('./home/home-night.webp'),
    nightHover: require('./home/home-hover-night.webp')
  },

  buyBuildBoard: {
    block: require('./buyBuildboard/block.webp'),
    upload: require('./buyBuildboard/upload.webp'),
    youtubeVimeo: require('./buyBuildboard/youtube_vimeo.webp'),
    totalPrice: require('./buyBuildboard/price_bg.webp')
  },

  achievements: {
    top_1000001: require('./achievements/top_1000001.png'),
    top_500001: require('./achievements/top_500001.png'),
    top_100001: require('./achievements/top_100001.png'),
    position_1: require('./achievements/position_1.png'),
    position_10: require('./achievements/position_10.png'),
    position_100: require('./achievements/position_100.png'),
    position_500: require('./achievements/position_500.png'),
    position_1000: require('./achievements/position_1000.png'),
    position_5000: require('./achievements/position_5000.png'),
    position_10000: require('./achievements/position_10000.png'),
    position_50000: require('./achievements/position_50000.png'),
    position_100000: require('./achievements/position_100000.png'),
    position_500000: require('./achievements/position_500000.png'),
    position_1000000: require('./achievements/position_1000000.png'),
    position_2000000: require('./achievements/position_2000000.png'),
    position_3000000: require('./achievements/position_3000000.png'),
    position_5000000: require('./achievements/position_5000000.png'),
    position_10000000: require('./achievements/position_10000000.png'),
    first_1000000: require('./achievements/first_1000000.png'),
    first_750000: require('./achievements/first_750000.png'),
    first_500000: require('./achievements/first_500000.png'),
    first_250000: require('./achievements/first_250000.png'),
    first_100000: require('./achievements/first_100000.png'),
    first_50000: require('./achievements/first_50000.png'),
    first_10000: require('./achievements/first_10000.png'),
    first_5000: require('./achievements/first_5000.png'),
    first_1000: require('./achievements/first_1000.png'),
    first_500: require('./achievements/first_500.png'),
    first_250: require('./achievements/first_250.png'),
    first_100: require('./achievements/first_100.png'),
    first_50: require('./achievements/first_50.png'),
    first_25: require('./achievements/first_25.png'),
    first_10: require('./achievements/first_10.png'),
    first_5: require('./achievements/first_5.png'),
    first_2: require('./achievements/first_2.png'),
    second_250000: require('./achievements/second_250000.png'),
    second_100000: require('./achievements/second_100000.png'),
    second_50000: require('./achievements/second_50000.png'),
    second_10000: require('./achievements/second_10000.png'),
    second_5000: require('./achievements/second_5000.png'),
    second_1000: require('./achievements/second_1000.png'),
    second_500: require('./achievements/second_500.png'),
    second_250: require('./achievements/second_250.png'),
    second_100: require('./achievements/second_100.png'),
    second_50: require('./achievements/second_50.png'),
    second_25: require('./achievements/second_25.png'),
    second_10: require('./achievements/second_10.png'),
    second_5: require('./achievements/second_5.png'),
    second_2: require('./achievements/second_2.png'),
    third_250000: require('./achievements/third_250000.png'),
    third_100000: require('./achievements/third_100000.png'),
    third_50000: require('./achievements/third_50000.png'),
    third_10000: require('./achievements/third_10000.png'),
    third_5000: require('./achievements/third_5000.png'),
    third_1000: require('./achievements/third_1000.png'),
    third_500: require('./achievements/third_500.png'),
    third_250: require('./achievements/third_250.png'),
    third_100: require('./achievements/third_100.png'),
    third_50: require('./achievements/third_50.png'),
    third_25: require('./achievements/third_25.png'),
    third_10: require('./achievements/third_10.png'),
    third_5: require('./achievements/third_5.png'),
    third_2: require('./achievements/third_2.png'),
    facebook_250000: require('./achievements/facebook_250000.png'),
    facebook_10000: require('./achievements/facebook_10000.png'),
    facebook_250: require('./achievements/facebook_250.png'),
    referral_500000: require('./achievements/referral_500000.png'),
    referral_50000: require('./achievements/referral_50000.png'),
    referral_500: require('./achievements/referral_500.png'),
    referral_50: require('./achievements/referral_50.png'),
    email_50000: require('./achievements/email_50000.png'),
    email_2500: require('./achievements/email_2500.png'),
    email_50: require('./achievements/email_50.png'),
    email_5: require('./achievements/email_5.png'),
    link_30: require('./achievements/link_30.png'),
    future_31536000: require('./achievements/future_31536000.png'),
    times_7: require('./achievements/times_7.png'),
    position_20000000: require('./achievements/position_20000000.png')
  },
  aboutPage: {
    one: require('./aboutPage/about-1.webp'),
    two: require('./aboutPage/about-2.webp'),
    three: require('./aboutPage/about-3.webp'),
    four: require('./aboutPage/about-4.webp'),
    five: require('./aboutPage/about-5.webp'),
    six: require('./aboutPage/about-6.webp'),
    seven: require('./aboutPage/about-7.webp'),
    eight: require('./aboutPage/about-8.webp'),
    iconOne: require('./aboutPage/icon-1.webp'),
    iconTwo: require('./aboutPage/icon-2.webp'),
    iconThree: require('./aboutPage/icon-3.webp'),
    iconFour: require('./aboutPage/icon-4.webp'),
    iconFive: require('./aboutPage/icon-5.webp'),
    iconSix: require('./aboutPage/icon-6.webp'),
    iconSeven: require('./aboutPage/icon-7.webp')
  }
};
