export const COLORS = {
  white: '#ffffff',
  white50: '#fafbff',
  white100: '#e3e3dd',
  brown: '#8d8a87',
  brown50: '#443f3d',
  silver: '#aab0b0',
  silver50: '#9f9d99',
  silver100: '#787677',
  silver150: '#757374',
  silver200: '#d2d2cf',
  black: '#0E0D0D',
  grey: '#262626',
  lightGrey: '#f5f5f5',
  purple: '#8390F5',
  mainRed: '#ec091f',
  errorRed: '#c53635',
  green: '#4FBB92',
  secondGreen: '#07ad07',
  successGreen: '#4cae4c',
  orange: '#b84d1d',
  video_player_bg: '#1e1e1e',
  lightPink: '#f1e5db',
  lightBlue: '#d0d7e6',
  lightGreen: '#d1d8c6',
  lightWhite: '#fbfcf9',
  green150: '#5e6a23',
  darkGrey: '#333',

  mediumDarkShadeOfCyanBlue: '#344054',
  grayLight: '#cccccc',
  ligthGreyBlue: '#d0d5dd',
  semiWhite: '#d0d5dd',
  white200: '#F5F6FE'
};
