import React from 'react';

import { Png } from '../../../../assets';
import * as Styled from './loading.styled';

const Logo = () => (
  <Styled.LogoSpinnerWrapper>
    <Styled.ImagesWrapper>
      <img src={Png.logo} alt='Logo' loading='lazy' />
      <img src={Png.preload} alt='Preload' loading='lazy' />
    </Styled.ImagesWrapper>
  </Styled.LogoSpinnerWrapper>
);

export default Logo;
