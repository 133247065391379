import styled from 'styled-components';

import { COLORS } from '../../../../theme';

export const MyContainer = styled.div<{ color?: string }>`
  .lds-ring {
    display: inline-block;
    position: relative;
    width: auto;
    height: auto;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    top: -23px;
    left: -15px;
    border: 8px solid ${({ color }) => color};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ color }) => color} transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const LogoSpinnerWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: ${COLORS.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
    height: 100%;
  }
`;
