import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { FacebookProvider } from 'react-facebook';

import './font-awesome.css';
import './index.css';
import App from './module/app/app';
import reportWebVitals from './reportWebVitals';

const SENTRY_KEY = process.env.REACT_APP_SENTRY_KEY;

Sentry.init({
  dsn: SENTRY_KEY,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/youronlinebillboard\.wdscode\.guru\//],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_ID;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <FacebookProvider appId={FACEBOOK_APP_ID!}>
    <App />
  </FacebookProvider>
);

reportWebVitals();
