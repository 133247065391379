import React from 'react';
import { Outlet, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import AdminLayout from '../admin-layout/admin-layout';
import Logo from '../common/component/loading/logo';
import { APP_KEYS } from '../common/constants';
import { PublicPage } from '../common/hocs';
import RequireAuth from '../require-auth/require-auth';
import ScrollToTop from '../scroll-to-top/scroll-to-top';

const HomeLayout = React.lazy(() =>
  import('../home-layout/home-layout').then((module) => ({
    default: module.HomeLayout
  }))
);

const Line = React.lazy(() =>
  import('../line/line').then((module) => ({
    default: module.Line
  }))
);

const BuyBillBoard = React.lazy(() =>
  import('../buy-bill-board/buy-bill-board').then((module) => ({
    default: module.BuyBillBoard
  }))
);
const HallOfFame = React.lazy(() =>
  import('../hall-of-fame/hall-of-fame').then((module) => ({
    default: module.HallOfFame
  }))
);

const About = React.lazy(() =>
  import('../about/about').then((module) => ({
    default: module.About
  }))
);

const Contact = React.lazy(() =>
  import('../contact/contact').then((module) => ({
    default: module.Contact
  }))
);

const History = React.lazy(() =>
  import('../history/history').then((module) => ({
    default: module.History
  }))
);

const BillBoard = React.lazy(() =>
  import('../bill-board/bill-board').then((module) => ({
    default: module.BillBoard
  }))
);

const TermsOfService = React.lazy(() =>
  import('../terms-of-service/terms-of-service').then((module) => ({
    default: module.TermsOfService
  }))
);

const Admin = React.lazy(() =>
  import('../admin/admin').then((module) => ({ default: module.Admin }))
);

const Login = React.lazy(() =>
  import('../login/login').then((module) => ({ default: module.Login }))
);

export const MainRouter = () => (
  <Router>
    <React.Suspense fallback={<Logo />}>
      <Routes>
        <Route
          path='/'
          element={
            <HomeLayout>
              <Outlet />
            </HomeLayout>
          }
        >
          <Route path={APP_KEYS.ROUTER_KEYS.LINE} element={<Line />} />
          <Route path={APP_KEYS.ROUTER_KEYS.BUY} element={<BuyBillBoard />} />
          <Route path={APP_KEYS.ROUTER_KEYS.HALL_OF_FAME} element={<HallOfFame />} />
          <Route path={APP_KEYS.ROUTER_KEYS.ABOUT} element={<About />} />
          <Route path={APP_KEYS.ROUTER_KEYS.CONTACT} element={<Contact />} />
          <Route path={APP_KEYS.ROUTER_KEYS.HISTORY} element={<History />} />
          <Route path={APP_KEYS.ROUTER_KEYS.BANNER} element={<BillBoard />} />
          <Route path={APP_KEYS.ROUTER_KEYS.THANK_YOU} element={<BillBoard thankYou />} />
          <Route path={APP_KEYS.ROUTER_KEYS.TERMS} element={<TermsOfService />} />
        </Route>
        <Route path={APP_KEYS.ROUTER_KEYS.LOGIN} element={<Login />} />
        <Route element={<AdminLayout />}>
          <Route element={<RequireAuth />}>
            <Route path={APP_KEYS.ROUTER_KEYS.ADMIN} element={<Admin />} />
          </Route>
        </Route>
        <Route
          path='*'
          element={
            <PublicPage>
              <HomeLayout component='error' />
            </PublicPage>
          }
        />
      </Routes>
    </React.Suspense>
    <ScrollToTop />
  </Router>
);
