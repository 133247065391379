import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import Logo from '../common/component/loading/logo';

const AdminLayout = () => (
  <Suspense
    fallback={
      <div className='spinner-wrapper'>
        <Logo />
      </div>
    }
  >
    <Outlet />
  </Suspense>
);

export default AdminLayout;
